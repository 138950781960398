import { inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromStore from '../store';
import { combineLatest, of } from 'rxjs';
import { CanActivateFn } from '@angular/router';

export const InitNotificationsGuard: CanActivateFn = () => {
  const store = inject(Store);

  return combineLatest([store.select(fromStore.getNotificationPermissionsChecked), store.select(fromStore.getSubscriptionsLoaded)])
    .pipe(
      tap(([checked, loaded]) => {
        if (!checked) {
          store.dispatch(fromStore.InitNotifications());
        }
      }),
      filter(([checked, loaded]) => checked && loaded),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
