import { inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromAddressesStore from '../../addresses/store';
import { combineLatest, of } from 'rxjs';
import { CanActivateFn } from '@angular/router';

export const GripLocationsGuard: CanActivateFn = route => {
  const store = inject(Store);

  return combineLatest([store.select(fromStore.getGripLocationsLoaded), store.select(fromStore.getGripLocationsLoading)])
    .pipe(
      withLatestFrom(store.select(fromAddressesStore.getAddress)),
      tap(([[loaded, loading], address]) => {
        if (!loaded && !loading && address) {
          store.dispatch(
            fromStore.LoadGripLocations({
              municipalityId: address.municipalityId || 0,
            })
          );
        }
      }),
      filter(([[loaded, loading], address]) => loaded && !loading),
      take(1)
    )
    .pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
};
